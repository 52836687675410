import { useTranslation } from 'react-i18next';
import BarChart from '../bar-chart/bar-chart';
import { useEffect, useState } from 'react';
import { errorMessagesHandler, getButtonText } from '@orascom/utils';
import { Loader } from '@orascom/common-components';
import { GraphData, GraphProps } from '../../definitions/analytics';
import { MostSearchesBreakdown as MostSearchesBreakdownType } from '../../definitions/interfaces/engagmenet';
import { useSearchParams } from 'react-router-dom';
import { Engagement as EngagementUtils } from '../../utils/engagement';
export function MostSearchesBreakdown(props: Readonly<GraphProps>) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const activeTabs = [
    'Most Searched Country',
    'Most Searched Destination',
    'Most Searched Unit Type',
  ];

  const [loading, setLoading] = useState(false);

  const [searchesBreakdown, setSearchesBreakdown] =
    useState<MostSearchesBreakdownType>();

  const [activeTab, setActiveTab] = useState<string>(activeTabs[0]);
  const [activeTabLabels, setActiveTabLabels] = useState<string[]>([]);
  const [activeTabData, setActiveTabData] = useState<GraphData[]>([]);

  const startTimeStamp = searchParams.get('start');
  const endTimeStamp = searchParams.get('end');

  const processTabData = (tab: string, searches: MostSearchesBreakdownType) => {
    let labels: string[] = [];
    let data: GraphData[] = [];

    switch (tab) {
      case 'Most Searched Country':
        labels = searches?.countries?.map((country) => country.name);
        data = searches?.countries?.map((country) => ({
          x: country.name,
          y: Number(country.count),
          currency: 'Search',
        }));
        break;
      case 'Most Searched Destination':
        labels =
          searches?.destinations?.map((destination) => destination.name) ?? [];
        data =
          searches?.destinations?.map((destination) => ({
            x: destination.name,
            y: Number(destination.count),
            currency: 'Search',
          })) ?? [];
        break;
      case 'Most Searched Unit Type':
        labels = searches?.unit_types?.map((type) => type.name) ?? [];
        data =
          searches?.unit_types?.map((type) => ({
            x: type.name,
            y: Number(type.count),
            currency: 'Search',
          })) ?? [];
        break;
      default:
        break;
    }

    return { labels, data };
  };

  useEffect(() => {
    if (activeTab && searchesBreakdown) {
      const { labels, data } = processTabData(activeTab, searchesBreakdown);
      setActiveTabLabels(labels);
      setActiveTabData(data);
    }
  }, [activeTab, searchesBreakdown]);

  useEffect(() => {
    const params = {
      from: props.startDateArg,
      to: props.endDateArg,
    };
    EngagementUtils.getAnalyticsMostSearchesBreakdown(params)
      .then((res) => {
        setSearchesBreakdown(res);
      })
      .catch((errors) => {
        errorMessagesHandler(errors.data);
      })
      .finally(() => {
        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTimeStamp, endTimeStamp]);
  const data = {
    labels: activeTabLabels,
    datasets: [
      {
        label: getButtonText(props.selectedDuration),
        data: activeTabData || [],
        backgroundColor: '#003A70',
        hoverBackgroundColor: '#4C759B',
        borderRadius: 8,
        borderSkipped: false,
        maxBarThickness: 30,
        categoryPercentage: 0.5,
        barPercentage: 1,
      },
    ],
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <BarChart
      description={t('mostSearchesBreakdownDescription')}
      title={t('mostSearchesBreakdown')}
      activeTabs={activeTabs}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      data={data}
      unMaintainAspectRatio
    />
  );
}

export default MostSearchesBreakdown;
