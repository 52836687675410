import { FilterParams } from '@orascom/api-interfaces';
import { LoginsOverTime } from '@orascom/broker-sales-man-common-components';
import { Engagement as EngagementApi } from '../api/engagement';
import { constructURL, parseError } from '@orascom/utils';
import {
  LeadsPerCountry,
  MostSearchesBreakdown,
  MostViewsBreakdown,
  PageViewsPerCountry,
  SalesPerCountry,
  SearchesWithNoAvailability,
} from '../definitions/interfaces/engagmenet';
import { ENDPOINTS } from '../api/endpoints';

export class Engagement {
  public static getAnalyticsLoginsOverTime(
    params: FilterParams
  ): Promise<LoginsOverTime> {
    return EngagementApi.getAnalyticsLoginsOverTime(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }

  public static getAnalyticsMostViewsBreakdown(
    params: FilterParams
  ): Promise<MostViewsBreakdown> {
    return EngagementApi.getAnalyticsMostViewsBreakdown(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }

  public static getAnalyticsMostSearchesBreakdown(
    params: FilterParams
  ): Promise<MostSearchesBreakdown> {
    return EngagementApi.getAnalyticsMostSearchesBreakdown(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }

  public static getAnalyticsPageViewsPerCountry(
    params: FilterParams
  ): Promise<PageViewsPerCountry[]> {
    return EngagementApi.getAnalyticsPageViewsPerCountry(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }

  public static getAnalyticsSalesPerCountry(
    params: FilterParams
  ): Promise<SalesPerCountry[]> {
    return EngagementApi.getAnalyticsSalesPerCountry(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }

  public static getAnalyticsLeadsPerCountry(
    params: FilterParams
  ): Promise<LeadsPerCountry[]> {
    return EngagementApi.getAnalyticsLeadsPerCountry(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }

  public static getAnalyticsSearchesWithNoAvailability(
    params: FilterParams
  ): Promise<SearchesWithNoAvailability> {
    return EngagementApi.getAnalyticsSearchesWithNoAvailability(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }

  public static getNoAvailabilityUrl = (params: FilterParams) => {
    return constructURL(
      process.env['NX__SHOPPER_ANALYTICS_API_ENDPOINT'],
      ENDPOINTS.exportSearchesWithNoAvailability.url,
      params
    );
  };
}
