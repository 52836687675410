import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { Loader, LoaderSmall } from '@orascom/common-components';
import { v4 as uuidv4 } from 'uuid';
import styles from './searches-with-no-availability.module.scss';
import { SearchesWithNoAvailability as SearchesWithNoAvailabilityType } from '../../definitions/interfaces/engagmenet';
import { GraphProps } from '../../definitions/analytics';
import { errorMessagesHandler } from '@orascom/utils';
import { PaginationStyled } from '@orascom/broker-sales-man-common-components';
import { Engagement } from '../../utils/engagement';
import Tick from '../../assets/icons/tick.svg';
import useDownload from 'libs/utils/src/hooks/use-download';

export function SearchesWithNoAvailability(props: Readonly<GraphProps>) {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 10;
  const lastIndex = currentPage * perPage;
  const firstIndex = lastIndex - perPage;
  const [loading, setLoading] = useState(true);
  const [paginationLoading, setPaginationLoading] = useState(true);
  const [searchedData, setSearchedData] =
    useState<SearchesWithNoAvailabilityType>();
  const startTimeStamp = props.startDateArg;
  const endTimeStamp = props.endDateArg;

  useEffect(() => {
    setCurrentPage(1);
    setPaginationLoading(true);
  }, [startTimeStamp, endTimeStamp]);

  useEffect(() => {
    const params = {
      page: currentPage.toString(),
      per_page: perPage.toString(),
      from: startTimeStamp,
      to: endTimeStamp,
    };

    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft = 0;
    }

    setPaginationLoading(true);

    Engagement.getAnalyticsSearchesWithNoAvailability(params)
      .then((res) => {
        setSearchedData(res);
      })
      .catch((errors) => errorMessagesHandler(errors.data))
      .finally(() => {
        setLoading(false);
        setPaginationLoading(false);
      });
  }, [startTimeStamp, endTimeStamp, currentPage, perPage]);

  const { triggerDownload } = useDownload({
    fullURL: Engagement.getNoAvailabilityUrl({
      from: props.startDateArg,
      to: props.endDateArg,
    }),
    method: 'GET',
    filename: 'Searches with no availablity',
    extension: 'xlsx',
    headers: { Accept: 'application/json' },
    projectType: 'analytics',
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={styles['wrapper']}>
      <p className="shoppes-analytics__sub-title">
        {t('searchesWithNoAvailabilityDescription')}
      </p>
      <div className={styles['title-container']}>
        <h2 className="shoppes-analytics__title">
          {t('searchesWithNoAvailability')}
        </h2>
        <button onClick={triggerDownload}>{t('downloadTable')}</button>
      </div>
      <div className={styles['table-wrapper']}>
        <div ref={scrollContainerRef} className={styles['table']}>
          <table>
            <thead>
              <tr className="">
                <th className=""></th>
                <th className="">{t('country').toUpperCase()}</th>
                <th className="">{t('destinations').toUpperCase()}</th>
                <th className="">{t('projects').toUpperCase()}</th>
                <th className="">{t('unitType').toUpperCase()}</th>
                <th className="">{t('builtUpArea').toUpperCase()}</th>
                <th className="">{t('priceRange').toUpperCase()}</th>
                <th className="">{t('currency').toUpperCase()}</th>
                <th className="">{t('noOfBedrooms').toUpperCase()}</th>
                <th className="">{t('noOfBathrooms').toUpperCase()}</th>
                <th className="">{t('finishingStatus').toUpperCase()}</th>
                <th className="">{t('onResale').toUpperCase()}</th>
                <th className="">{t('noOfHits').toUpperCase()}</th>
              </tr>
            </thead>
            <tbody>
              {paginationLoading ? (
                <tr>
                  <td colSpan={6} className={styles['small-loader']}>
                    <LoaderSmall />
                  </td>
                </tr>
              ) : (
                searchedData?.data.map((row, index) => {
                  const globalIndex = (currentPage - 1) * perPage + (index + 1);

                  return (
                    <tr key={uuidv4()}>
                      <td>{globalIndex}.</td>
                      <td>{row.countries ? row.countries : '-'}</td>
                      <td>{row.destinations ? row.destinations : '-'}</td>
                      <td>{row.projects ? row.projects : '-'}</td>
                      <td>{row.unit_types ? row.unit_types : '-'}</td>
                      <td>{row.built_up_areas ? row.built_up_areas : '-'}</td>
                      <td>{row.price_range ? row.price_range : '-'}</td>
                      <td>{row.currency ? row.currency : '-'}</td>
                      <td>{row.bedrooms ? row.bedrooms : '-'}</td>
                      <td>{row.bathrooms ? row.bathrooms : '-'}</td>
                      <td>
                        {row.finishing_status ? row.finishing_status : '-'}
                      </td>
                      <td>
                        {row.on_resale === '1' ? (
                          <img src={Tick} alt="" role="presentation" />
                        ) : (
                          '-'
                        )}
                      </td>
                      <td>
                        <div className={styles['last-column']}>
                          {row.hits_count
                            ? row.hits_count.toLocaleString()
                            : '-'}
                        </div>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
        <div className={styles['pagination-info']}>
          {searchedData && searchedData?.meta.last_page > 1 ? (
            <p className={styles['count']}>
              {t('showing')}{' '}
              <span>
                {' '}
                {firstIndex + 1} -{' '}
                {lastIndex > searchedData.meta.total
                  ? searchedData.meta.total
                  : lastIndex}{' '}
              </span>{' '}
              {t('outOf')} <span>{searchedData?.meta.total} </span>
              {t('searches')}
            </p>
          ) : null}

          <PaginationStyled
            currentPage={currentPage}
            totalCount={searchedData?.meta.total ?? 1}
            pageSize={perPage}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
        </div>
      </div>
    </div>
  );
}

export default SearchesWithNoAvailability;
