import { useTranslation } from 'react-i18next';
import styles from './sidebar-component.module.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CURRENCIES_ENUM, Route } from '@orascom/api-interfaces';
import {
  Sidebar as SideNavBar,
  Menu,
  MenuItem,
  SubMenu,
} from 'react-pro-sidebar';
import {
  CURRENCIES,
  CurrencyContext,
  useWindowDimensions,
} from '@orascom/utils';
import { RefObject, useContext, useEffect, useState } from 'react';
import logo from '../../assets/images/logo.webp';
import { SelectDropdown, Tooltip } from '@orascom/common-components';
import InfoIcon from '../../assets/icons/info.svg';

export function SidebarMenuItem({
  ref,
  id,
  route,
  icon,
  activeIcon,
  isActive,
  label,
  onClick,
}: Readonly<{
  ref?: RefObject<HTMLLIElement>;
  id?: string;
  route: Route;
  icon: React.ReactNode;
  activeIcon: React.ReactNode;
  isActive: boolean;
  label: string;
  onClick?: () => void;
}>) {
  return (
    <MenuItem
      ref={ref}
      id={id}
      icon={isActive ? activeIcon : icon}
      component={<Link to={route.path} />}
      active={isActive}
      onClick={onClick}
    >
      {label}
    </MenuItem>
  );
}

function SidebarLogoutButton({
  onClick,
  loggingOut,
}: Readonly<{
  onClick: () => void;
  loggingOut: boolean;
}>) {
  const { t } = useTranslation();
  return (
    <button
      className={styles['logout']}
      onClick={onClick}
      disabled={loggingOut}
    >
      {t('logOut')}
    </button>
  );
}

export interface SidebarComponentProps {
  logoutHandler: () => void;
  loggingOut: boolean;
  children: React.ReactNode;
  countrySelection?: React.ReactNode;
  subtitle?: string;
}
export function SidebarComponent(props: Readonly<SidebarComponentProps>) {
  const { width } = useWindowDimensions();
  const [navOpen, setNavOpen] = useState(false);
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    document.body.style.overflow = navOpen ? 'hidden' : '';
    document.body.style.height = navOpen ? '100%' : '';
  }, [navOpen]);

  useEffect(() => {
    setNavOpen(false);
  }, [location.pathname]);

  const [showCurrencyTooltip, setShowCurrencyTooltip] = useState(false);
  const { setActiveCurrency, disableCurrencySetting } =
    useContext(CurrencyContext);

  const [selectedCurrency, setSelectedCurrency] = useState(
    JSON.parse(localStorage.getItem('currency') as string) || {
      label: CURRENCIES_ENUM.EGP,
      value: 1,
    }
  );

  useEffect(() => {
    if (disableCurrencySetting.disabled) {
      setSelectedCurrency({
        label: CURRENCIES_ENUM.USD,
        value: 1,
      });
    }
  }, [disableCurrencySetting.disabled]);

  useEffect(() => {
    localStorage.setItem('currency', JSON.stringify(selectedCurrency));
  }, [selectedCurrency]);

  const currencyOptions = CURRENCIES.map((cur, ind) => ({
    label: cur,
    value: ind,
  }));

  return (
    <>
      <button
        className={`${styles['burger']} ${navOpen && styles['burger--open']}`}
        onClick={() => setNavOpen(!navOpen)}
      >
        <div className={styles['bar1']}></div>
        <div className={styles['bar2']}></div>
        <div className={styles['bar3']}></div>
      </button>

      {(navOpen || width > 768) && (
        <aside className={styles['wrapper']}>
          <SideNavBar
            backgroundColor="#ffffff"
            width="230px"
            rtl={i18n.language === 'ar'}
          >
            <Menu>
              <div className={styles['logo']}>
                <img src={logo} alt="orascom" loading="lazy" />
                <p className={styles['subtitle']}>{props.subtitle}</p>
              </div>
              <div className={styles['currency-wrapper']}>
                <SelectDropdown
                  options={currencyOptions}
                  placeholder={selectedCurrency.label}
                  defaultValue={selectedCurrency}
                  // TODO change this after implementing localization
                  onChange={(val) => {
                    if (val) {
                      setActiveCurrency(val.label);
                      setSelectedCurrency(val);
                    }
                  }}
                  isSearchable={false}
                  disabled={disableCurrencySetting.disabled}
                  selectedOption={selectedCurrency}
                />
                {disableCurrencySetting.info && (
                  <div>
                    <button onClick={() => setShowCurrencyTooltip(true)}>
                      <img src={InfoIcon} alt="info-icon" />
                    </button>
                    {showCurrencyTooltip && (
                      <Tooltip
                        portal="broker"
                        position="bottom"
                        onClose={() => setShowCurrencyTooltip(false)}
                        className="side-nav-broker__tooltip"
                      >
                        <span>{disableCurrencySetting.info}</span>
                      </Tooltip>
                    )}
                  </div>
                )}
                {props.countrySelection}
              </div>

              {props.children}
              <SidebarLogoutButton
                onClick={props.logoutHandler}
                loggingOut={props.loggingOut}
              />
            </Menu>
          </SideNavBar>
        </aside>
      )}
    </>
  );
}
export default SidebarComponent;
