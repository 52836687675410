type ProjectType = 'sales-man' | 'analytics' | 'broker' | 'shopper';

function getToken(projectType: ProjectType): string | null {
  const tokenKeyMap: { [key: string]: string } = {
    'sales-man': process.env['NX_SALESMAN_ACCESS_TOKEN_KEY'] as string,
    analytics: process.env['NX_SHOPPER_ANALYTICS_ACCESS_TOKEN_KEY'] as string,
    broker: process.env['NX_BROKER_ACCESS_TOKEN_KEY'] as string,
    shopper: process.env['NX_SHOPPER_ACCESS_TOKEN_KEY'] as string,
  };

  const tokenKey = tokenKeyMap[projectType];
  return localStorage.getItem(tokenKey);
}

async function downloadFile({
  fullURL,
  method = 'GET',
  body,
  headers,
  projectType,
}: {
  fullURL: string;
  method?: string;
  body?: string;
  headers?: HeadersInit;
  projectType: ProjectType;
}): Promise<Blob> {
  const url = new URL(fullURL);

  const response = await fetch(url.toString(), {
    method: method,
    headers: {
      Authorization: `Bearer ${getToken(projectType)}`,
      ...headers,
    },
    body,
  });

  if (!response.ok) {
    throw new Error(`Failed to download file: ${response.statusText}`);
  }

  if (response.status === 302) {
    throw new Error('File not found');
  }

  return response.blob();
}

export interface TriggerFileDownloadOptions {
  fullURL: string;
  filename?: string;
  extension?: string;
  method?: string;
  body?: string;
  headers?: HeadersInit;
  projectType: ProjectType;
}
export async function triggerFileDownload({
  fullURL,
  filename,
  extension,
  method,
  body,
  headers,
  projectType,
}: TriggerFileDownloadOptions) {
  try {
    const blob = await downloadFile({
      fullURL,
      method,
      body,
      headers,
      projectType,
    });

    const fileUrl = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = fileUrl;
    a.download = `${filename || 'file'}${extension ? '.' + extension : ''}`;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(fileUrl);
    document.body.removeChild(a);
  } catch (error) {
    if (error instanceof Error) {
      console.log(error);
    }
    console.error('Error downloading file:', error);
    throw error;
  }
}
